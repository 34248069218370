<template>
  <div class="home">
    <Nav></Nav>
    <div class="main" v-if="detail">
      <div class="patientInfo">
        {{ detail.patientName }}，
        {{ detail.gender === 'Male' ? '男' : '女' }}，{{ detail.age }}岁，{{
          detail.examTime
        }}
        创建档案
      </div>
      <div class="content">
        <div class="table_title">OCT影像</div>
        <table border="1" class="table">
          <tbody>
            <tr class="td_title">
              <td width="10%" rowspan="2">影像</td>
              <td width="45%">OD</td>
              <td width="45%">OS</td>
            </tr>
            <tr class="octImg">
              <td valign="top">
                <img
                  v-for="(item, index) in detail.analyzeImagesR"
                  :key="index"
                  :preview="'R' + index"
                  v-lazy="baseUrl + item"
                  alt=""
                />
              </td>
              <td valign="top">
                <img
                  v-for="(item, index) in detail.analyzeImagesL"
                  :key="index"
                  :preview="'L' + index"
                  v-lazy="baseUrl + item"
                  alt=""
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="table_title aiOpinion">
          <span align="left">诊断结果</span>
          <span align="right">注：AMD：年龄相关性黄斑变性</span>
        </div>
        <table border="1" class="table">
          <tbody>
            <tr class="td_title">
              <td width="50%">右眼</td>
              <td width="50%">左眼</td>
            </tr>
            <tr>
              <td>{{ detail.aiOpinionR }}</td>
              <td>{{ detail.aiOpinionL }}</td>
            </tr>
          </tbody>
        </table>

        <div v-if="detail.doctorOpinion" class="table_title">诊断意见</div>
        <table v-if="detail.doctorOpinion" border="1" class="table">
          <tbody>
            <tr x>
              <td align="left" class="doctorOpinion" colspan="2">
                {{ detail.doctorOpinion }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="result">
        <div class="table_title">诊断结果</div>
        <table border="1" class="table">
          <tbody>
            <tr>
              <td>右眼</td>
              <td>左眼</td>
            </tr>
            <tr>
              <td>123</td>
              <td>1222</td>
            </tr>
            <tr>
              <td colspan="2">意见</td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <div class="bottom">
        <el-button
          style="float:right;margin-left:30px"
          type="danger"
          @click="goPrint"
          >报告打印</el-button
        >
        <el-button style="float:right" type="primary" @click="addDiagnose"
          >编辑诊断意见</el-button
        >
      </div>
    </div>
    <el-dialog title="添加诊断意见" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false" :close-on-press-escape="false">
      <div>
        <el-input
          type="textarea"
          maxlength="255"
          show-word-limit
          :rows="8"
          v-model="diagnose"
          placeholder="请输入诊断意见"
        ></el-input>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Service from '@/api/service'
export default {
  name: 'FileList',
  components: {
    Nav
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      id: '',
      tableList: [],
      showDialog: false,
      title: '',
      form: {
        key: '',
        gender: ''
      },
      diagnose: '',
      dialogVisible: false,
      detail: null
    }
  },
  created() {
    // console.log('$route.query.id', this.$route.query.id)
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    async getDetail() {
      let data = {
        examId: this.id
      }
      this.detail = await Service.getExamDetails(data)
    },
    addAccount() {
      this.showDialog = true
      this.title = '添加账号'
    },
    addDiagnose() {
      this.diagnose = ''
      this.dialogVisible = true
    },
    async sure() {
      let data = {
        examId: this.id,
        doctorOpinion: this.diagnose
      }
      await Service.addDoctorOpinion(data)
      this.$message.success('添加成功！')
      this.getDetail()
      this.dialogVisible = false
    },
    goPrint() {
      this.$router.push({
        path: '/report',
        query: {
          id: this.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.patientInfo {
  width: calc(100% - 20px);
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, 0.08);
}
.td_title {
  color: #1d73bc;
}
.content {
  width: 100%;
  height: calc(100% - 130px);
  background: #fff;
  box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  border-radius: 4px;
  margin-top: 10px;
  overflow: auto;
}
.doctorOpinion {
  padding: 20px;
  color: #1d73bc;
}
.octImg img {
  width: 100%;
  cursor: pointer;
}
.result {
  width: 100%;
  height: 280px;
  background: #fff;
  box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  border-radius: 4px;
  margin-top: 10px;
}
.bottom {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 10px;
}
.table_title {
  padding: 15px 15px 10px 15px;
  font-weight: 600;
  color: #666;
}
.aiOpinion:after {
  display: block;
  content: '';
  clear: both;
}
.aiOpinion span:first-child {
  display: block;
  float: left;
}
.aiOpinion span:last-child {
  display: block;
  float: right;
  font-weight: 500;
  color: #333;
}
.table {
  border: 1px solid #eee;
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  text-align: center;
  table-layout: fixed;
}
td {
  padding: 10px;
  word-wrap: break-word;
}
</style>
